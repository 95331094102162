import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className="cursor-pointer"
  >
    <circle cx={11} cy={11} r={10.5} fill="#fff" stroke="#CBC698" />
    <path
      d="M9.052 15.275c-.313 0-.612-.124-.833-.345l-2.516-2.516a.694.694 0 0 1 .98-.98l2.369 2.368 6.264-6.265a.694.694 0 0 1 .98.98L9.885 14.93c-.22.22-.52.345-.832.345"
      fill="#fff"
    />
  </svg>
);
export default SVGComponent;
