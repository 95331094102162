import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={5}
    height={19}
    viewBox="0 0 5 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="cursor-pointer"
    {...props}
  >
    <circle cx={2.173} cy={2.437} r={2.173} fill="#000" />
    <circle cx={2.177} cy={8.957} r={2.173} fill="#000" />
    <circle cx={2.177} cy={16.563} r={2.173} fill="#000" />
  </svg>
);

export default SVGComponent;
