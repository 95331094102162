import * as React from "react";

const NextPageIcon = (props) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <rect width={40} height={40} fill="url(#pattern0_7430_17)" />
    <defs>
      <pattern
        id="pattern0_7430_17"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use xlinkHref="#image0_7430_17" transform="scale(0.0104167)" />
      </pattern>
      <image
        id="image0_7430_17"
        width={96}
        height={96}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAACs0lEQVR4nO2dTWqUQRCGZ6Nb0WOoh3Dv1hzAqhDwLv7EiIscwKrwQboiuDOudaEr9SCagIIjSjMoEqKZhfPVT78P1DpT71PdPQyhe7EAAAAAAAAAAAAAAGBNDg/opgnvNqUPTfnUlH9UrqZ82nvtPffe3QZlmrYuN+WnpvTdOxTzKuGlCT3pWcwevim9cg9AY1QTPp5Vwmry/Ru3QNWU9+bc85feDVu0El4+l+0bGxdgSo/dm9WY1ZQeblxAE/7o3ahFLeH3GxdgQifujWrMasKfNy8gQKMWuCBAIcB9Cg0rwD8IwxbkH4Y5FM4AhQD3KTSsAP8gDFvQfwvhSzvgrab8xjvc4c6AJvT1UOh2/7vTtHPFhF57BzyMgPZH+L/IIKGEgHZO+FkkpBfQ/hF+BgnpBRzJ9p11PkeXEPFgTi/AhN9NE19bV0K0lZBfgOaWUEOA5pVQR4DmlFBLgOaTUE+A5pJQU4DmkVBXgOaQUFuAxpdQX4DGljCGAI0rYRwBGlPCWAI0noTxBGgsCWMKUHr74tm9qxF+xh5QAIUJf0ABFCr8wQRQuPAHEkAhwx9EAIUNfwABFDr84gIofPiFBVCK8IsKoDThFxRAqcIvJoDShV9IAKUMv4gASht+CQGHF/xndOTwSwhoq2vPbmUMv4QAu0BC5PDLCLC/SIgefikBdkZChvDLCbBeQif9YM4Qfk0BmqsgQCHAfQoNK8A/CMMW5B+GORTOAIUA9yk0rAD/IKzqFtQvJ/Vu0oJWU/40hwBcXayOVxc35Ufek2ZBqynd37iAfkU7rq/n86Z/eTTdvb6Yg/5sh/e0WbQS3l3M+n6M8LF70xqjmvDL/f2dS7MJ+C1BeW/k7agJfeuTP3v4Z8+E/nJE/wYwxNsCQierXvnBbHs+AAAAAAAAAAAAAFhU4Ccn7xc7ZDy0hgAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
);
export default NextPageIcon;
