import React from "react";

import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";

import Login from "../pages/Auth/Login";
import Orders from "../pages/Orders";
import Kiosk from "../pages/Kiosk";
import AccessControl from "../pages/AccessControl";

import PrivateRoute from "./PreventRoute";

const AppStack = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route element={<PrivateRoute />}>
          <Route path="/orders" element={<Orders />} />
          <Route path="/kiosks" element={<Kiosk />} />
          <Route path="/accesscontrol" element={<AccessControl />} />
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default AppStack;
