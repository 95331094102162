import { useLocation, useNavigate } from "react-router-dom";

import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";

import secureLocalStorage from "react-secure-storage";
import Logo from "../../images/svg/Logo";
import AccessControlIconPageNotSelected from "../../images/svg/Sidebar/AccessControlIconPageNotSelected";
import AccessControlIconPageSelected from "../../images/svg/Sidebar/AccessControlIconPageSelected";
import BagIconPageNotSelected from "../../images/svg/Sidebar/BagIconPageNotSelected";
import BagIconPageSelected from "../../images/svg/Sidebar/BagIconPageSelected";
import KioskIconePageNotSelected from "../../images/svg/Sidebar/KioskIconePageNotSelected";
import KioskIconePageSelected from "../../images/svg/Sidebar/KioskIconePageSelected";
import LogoutIcon from "../../images/svg/Sidebar/LogoutIcon";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { logout, setSearchingOrders } = useContext(GlobalContext);
  const user = secureLocalStorage.getItem("@USER_IMAGE/NAME");

  return (
    <div className="bg-secondarycolor border-1 border-border rounded-e-2xl w-[230px] flex flex-col justify-between fixed h-screen py-8">
      <div className="flex flex-col justify-center">
        <div className="self-center mb-8">
          <Logo width="96px" height="96px" alt="Gold Spell Logo" />
        </div>

        <ul className="p-2.5 flex flex-col gap-[15px]">
          {user?.role === "Administrador geral" && (
            <li
              onClick={() => {
                setSearchingOrders("");
                navigate("/orders");
              }}
              className={`flex items-center gap-2 h-10 rounded-lg pl-2 ${
                location.pathname === "/orders"
                  ? "bg-primarycolor text-white"
                  : "bg-transparent text-grey1"
              } cursor-pointer`}
            >
              {location.pathname === "/orders" ? (
                <BagIconPageSelected />
              ) : (
                <BagIconPageNotSelected />
              )}
              <p className="text-base font-mediump">Pedidos</p>
            </li>
          )}

          <li
            onClick={() => {
              setSearchingOrders("");
              navigate("/kiosks");
            }}
            className={`flex items-center gap-2 h-10 rounded-lg pl-2 ${
              location.pathname === "/kiosks"
                ? "bg-primarycolor text-white"
                : "bg-transparent text-grey1"
            } cursor-pointer`}
          >
            {location.pathname === "/kiosks" ? (
              <KioskIconePageSelected />
            ) : (
              <KioskIconePageNotSelected />
            )}
            <p className="text-base font-mediump">Quiosques</p>
          </li>

          {user?.role === "Administrador geral" && (
            <li
              onClick={() => navigate("/accesscontrol")}
              className={`flex items-center gap-2 h-10 rounded-lg pl-2 ${
                location.pathname === "/accesscontrol"
                  ? "bg-primarycolor text-white"
                  : "bg-transparent text-grey1"
              } cursor-pointer`}
            >
              {location.pathname === "/accesscontrol" ? (
                <AccessControlIconPageSelected />
              ) : (
                <AccessControlIconPageNotSelected />
              )}
              <p className="text-base font-mediump">Gerenciar acessos</p>
            </li>
          )}
        </ul>
      </div>

      <button
        onClick={() => {
          logout();
          navigate("/");
        }}
        className="text-base font-mediump text-grey1 flex align-center gap-2 p-2.5 ml-2 mt-[400px] hover:text-primarycolor"
      >
        <LogoutIcon /> Sair
      </button>
    </div>
  );
};

export default Sidebar;
