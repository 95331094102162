import React from 'react'
import InputMask from 'react-input-mask'

const Input = ({
  label,
  placeholder,
  error,
  mask,
  disabled,
  register,
  registerKey,
  height,
  validate,
  onBlur
}) => (
  <div className="flex flex-col items-start w-full">
    <label className="text-base my-2 font-regularp text-black">{label}</label>
    {mask ? (
      <InputMask
        className={`
          text-lg font-regularp w-full pl-3 rounded-xl border-1 bg-white placeholder:text-grey2
          ${height ? height : 'h-12'}
          ${error ? 'border-red1' : 'border-border'}
                `}
        mask={mask}
        placeholder={placeholder}
        name={registerKey}
        {...register(registerKey, validate)}
        disabled={disabled}
        onBlur={onBlur}
        autoComplete="one-time-code"
      />
    ) : (
      <input
        className={`
          text-lg font-regularp w-full pl-3 rounded-xl border-1 bg-white placeholder:text-grey2
          ${height ? height : 'h-12'}
          ${error ? 'border-red1' : 'border-border'}
        `}
        placeholder={placeholder}
        {...register(registerKey, validate)}
        disabled={disabled}
        id={registerKey}
        onBlur={onBlur}
        autoComplete="one-time-code"
      />
    )}
    <div className='relative w-full'>
      <span className="absolute bottom-10 right-0 mr-3 px-1 rounded-full bg-red-blue-split text-xs font-semiboldc text-red1">
        {error?.message}
      </span>
    </div>
  </div>
)

export default Input
