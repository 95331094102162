import React, { useContext, useEffect, useState } from "react";

import ReactLoading from "react-loading";

import { GlobalContext } from "../../../../context/GlobalContext";

import Header from "../../../../components/Header";
import OrderCard from "../OrderCard";
import PageController from "../PageController";
import Pagination from "../Pagination";
import ScrollBottom from "../ScroolBottom";

const ContentPageContainer = () => {
  const {
    user,
    ordersKiosks,
    isLoadingOrdersKiosks,
    currentPage,
    setCurrentPage,
  } = useContext(GlobalContext);

  const [listItens, setListItens] = useState([]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  let div = document.getElementById("test");

  const syncScroll = () => {
    div = document.getElementById("test");

    if (listItens && listItens.length) {
      listItens.forEach((item) => {
        let divLoop = document.getElementById(item);

        if (divLoop) {
          divLoop.scrollLeft = div.scrollLeft;
        }
      });
    }
  };

  useEffect(() => {
    const div = document.getElementById("test");

    if (div && listItens) {
      div.addEventListener("scroll", syncScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener("scroll", syncScroll);
      }
    };
  }, [listItens]);

  return (
    <div className="laptop:max-w-[800px] laptopXl:max-w-[900px] desktop:max-w-[950px] desktopXl:max-w-[1200px]">
      <Header pageName="Pedidos quiosques" user={user} />

      <main>
        <PageController />

        <div className="laptop:w-[800px] laptopXl:w-[900px] desktop:w-[950px] desktopXl:w-[1200px]">
          {isLoadingOrdersKiosks ? (
            <div className="flex items-center mt-5">
              <h1 className="text-[30px] font-mediump text-grey1 mb-3">
                Carregando pedidos
              </h1>

              <ReactLoading
                type={"balls"}
                color={"#CBC698"}
                height={20}
                width={20}
              />
            </div>
          ) : (
            <>
              <ul className="flex flex-col gap-3 mt-5 mb-5">
                {ordersKiosks?.length ? (
                  ordersKiosks?.map((order) => (
                    <OrderCard
                      key={order.id}
                      order={order}
                      setListItens={setListItens}
                    />
                  ))
                ) : (
                  <li className="flex justify-center w-full h-[120px] border-1 border-border rounded-xl px-4 items-center gap-3">
                    <p className="text-grey3 font-mediump text-base">
                      Nenhum pedido encontrado
                    </p>
                  </li>
                )}

                <ScrollBottom width={"2800px"} />
              </ul>

              {ordersKiosks?.length === 50 && (
                <Pagination
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              )}
            </>
          )}

          <div className="h-[80px]" />
        </div>
      </main>
    </div>
  );
};

export default ContentPageContainer;
