import React, { useContext, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { GlobalContext } from '../../context/GlobalContext';

import UserIcon from '../../images/png/Header/UserIcon.png';
import ArrowDownIcon from '../../images/svg/Header/ArrowDownIcon';
import LogoutIcon from '../../images/svg/Header/LogoutIcon';
import BackArrowIcon from '../../images/svg/Header/BackArrowIcon';
import secureLocalStorage from 'react-secure-storage';

const Header = ({ pageName, backArrow, returnPage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { logout } = useContext(GlobalContext);

  const toggle = (previous) => {
    setIsOpen(!previous);
  };

  const user = secureLocalStorage.getItem('@USER_IMAGE/NAME');

  return (
    <header className="w-full h-14 bg-white flex items-center justify-between mt-14 mb-10">
      <div className="flex items-center gap-3">
        {backArrow && (
          <button onClick={returnPage}>
            <BackArrowIcon />
          </button>
        )}
        <h1 className="text-3xl font-semiboldp text-primarycolor">
          {pageName}
        </h1>
      </div>

      <div
        onClick={() => toggle(isOpen)}
        className="border-1 border-border rounded-full flex items-center gap-3 p-2 relative cursor-pointer"
      >
        <div className="bg-secondarycolor rounded-full">
          {user?.image ?
            <img src={user?.image} alt="User" className="object-cover w-8 h-8 rounded-full border-1 border-primarycolor" />
            :
            <img src={UserIcon} alt="User" className="object-cover w-8 h-8 rounded-full border-1 border-primarycolor" />
          }
        </div>

        <p className='text-base font-regularp'>{user ? user?.name : 'Bem vindo'}</p>

        <ArrowDownIcon />

        {isOpen && (
          <div className=" flex items-center p-2 absolute top-full left-0 w-full">
            <button
              onClick={() => { logout(); navigate('/') }}
              className="text-base font-mediump text-grey1 flex align-center gap-2 p-1 ml-2 cursor-pointer hover:text-primarycolor"
            >
              <LogoutIcon /> Sair
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
