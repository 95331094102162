const ScrollBotton = ({ width }) => {

  const scrollContainerStyle = {
    position: 'fixed',
    width: '1200px',
    padding: '10px',
    paddingLeft: '10px',
    backgroundColor: '#fff',
    bottom: '30px',
    zIndex: 1,
    border: '1px solid #d7d7d7',
    borderBottom: '1px solid transparent',
    borderRadius: '10px',
    overflowX: 'scroll',
  };

  const fillerTextStyle = {
    width: width,
  };

  const bottomContainerStyle = {
    position: 'fixed',
    width: '1200px',
    padding: '20px',
    backgroundColor: '#fff',
    bottom: '15px',
    border: '1px solid #d7d7d7',
    borderRadius: '10px',
    boxShadow: '0px 2px 24px -9px',
  };

  return (
    <>
      <div id="test" className="scrollLowerUp laptop:max-w-[800px] laptopXl:max-w-[900px] desktop:max-w-[950px] desktopXl:max-w-[1200px]" style={scrollContainerStyle}>
        <p style={fillerTextStyle}></p>
      </div>
      <div style={bottomContainerStyle} className="laptop:max-w-[800px] laptopXl:max-w-[900px] desktop:max-w-[950px] desktopXl:max-w-[1200px]"></div>
    </>
  );
};

export default ScrollBotton;
