import { toast } from "react-toastify";
import api from "../api";

export const getOrders = async ({ currentPage, search, startDate, endDate, setIsLoadingOrders, setOrders }) => {
  try {
    setIsLoadingOrders(true);
    const { data } = await api.get(`/orders?page=${currentPage}&search=${search}&startDate=${startDate ? startDate : 0}&endDate=${endDate ? endDate : 0}`);

    setOrders(data.orders);
  } catch (error) {
    toast.error(error.response?.data.messsage || 'Erro ao buscar os pedidos')
  } finally {
    setIsLoadingOrders(false);
  }
};
