import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { formatDate } from "../../../../Utils/formats";

const OrderCard = ({ order, setListItens }) => {
  const [idOne, setIdOne] = useState("");

  useEffect(() => {
    const idDiv1 = uuidv4();

    setIdOne(idDiv1);

    setListItens((prevList) => [...prevList, idDiv1]);
  }, []);

  return (
    <li
      id={idOne}
      className="overflow-hidden w-full h-[96px] border-1 border-border rounded-xl px-4 flex items-center gap-3"
    >
      <div className="flex flex-col items-center max-h-12 min-w-[410px] gap-1.5">
        <p className="font-mediump text-sm text-black text-center max-h-12">
          {order.product_offer_name || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Nome do produto da oferta
        </p>
      </div>

      <div className="flex flex-col items-center max-h-12 min-w-[110px] gap-1.5">
        <p className="font-mediump text-sm text-black text-center max-h-12">
          {formatDate(order.date_now) || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Data da compra
        </p>
      </div>

      <div className="flex flex-col items-center min-w-36 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.product_qty || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Quantidade do produto
        </p>
      </div>

      <div className="flex flex-col items-center min-w-36 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.contact_doc || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Documento do contato
        </p>
      </div>

      <div className="flex flex-col items-center min-w-72 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.contact_name || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Nome do contato
        </p>
      </div>

      <div className="flex flex-col items-center min-w-96 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.contact_email || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          E-mail do contato
        </p>
      </div>

      <div className="flex flex-col items-center min-w-32">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.marketplace_name || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Nome marketplace
        </p>
      </div>

      <div className="flex flex-col items-center min-w-32">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.marketplace_id || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Id marketplace
        </p>
      </div>

      <div className="flex flex-col items-center min-w-32">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.status || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Status
        </p>
      </div>

      <div className="flex flex-col items-center min-w-28 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.type || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Tipo
        </p>
      </div>

      <div className="flex flex-col items-center min-w-36">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.method_payment || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Método de pagamento
        </p>
      </div>

      <div className="flex flex-col items-center min-w-28 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.installments_qty || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Parcelamento
        </p>
      </div>

      <div className="flex flex-col items-center min-w-24 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.currency || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Moeda
        </p>
      </div>

      <div className="flex flex-col items-center min-w-28 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.gross || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Bruto
        </p>
      </div>

      <div className="flex flex-col items-center min-w-36 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.marketplace_value || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Valor do marketplace
        </p>
      </div>

      <div className="flex flex-col items-center min-w-32 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.affiliate_value || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Valor do afiliado
        </p>
      </div>

      <div className="flex flex-col items-center min-w-32 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.shipping_value || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Valor do frete
        </p>
      </div>

      <div className="flex flex-col items-center min-w-32 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.payment_net || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Rede de pagamento
        </p>
      </div>

      <div className="flex flex-col items-center min-w-36 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.product_total_value || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Valor total de produtos
        </p>
      </div>

      <div className="flex flex-col items-center min-w-32 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.payment_discount_value || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Valor de desconto
        </p>
      </div>

      <div className="flex flex-col items-center min-w-32 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.tax_value || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Valor do imposto
        </p>
      </div>

      <div className="flex flex-col items-center min-w-32 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.installments_value || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Valor das parcelas
        </p>
      </div>

      <div className="flex flex-col items-center min-w-32 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.product_id || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Id do produto
        </p>
      </div>

      <div className="flex flex-col items-center min-w-96 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.product_name || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Nome do produto
        </p>
      </div>

      <div className="flex flex-col items-center min-w-36 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.payment_refund_reason || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Motivo do reembolso
        </p>
      </div>

      <div className="flex flex-col items-center min-w-48 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.contact_company_name || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Nome da empresa do contato
        </p>
      </div>

      <div className="flex flex-col items-center min-w-96 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.contact_address || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Endereço do contato
        </p>
      </div>

      <div className="flex flex-col items-center min-w-36 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.contact_address_number || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Número do endereço
        </p>
      </div>

      <div className="flex flex-col items-center min-w-36 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.contact_phone_number || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Telefone do contato
        </p>
      </div>

      <div className="flex flex-col items-center min-w-72 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.contact_address_comp || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Complemento do endereço
        </p>
      </div>

      <div className="flex flex-col items-center min-w-44 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.contact_address_city || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Cidade
        </p>
      </div>

      <div className="flex flex-col items-center min-w-20 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.contact_address_state || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Estado
        </p>
      </div>

      <div className="flex flex-col items-center min-w-20 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.contact_address_country || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          País
        </p>
      </div>

      <div className="flex flex-col items-center min-w-28 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.contact_address_zip_code || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">Cep</p>
      </div>

      <div className="flex flex-col items-center min-w-40 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.contact_address_phone_local_code || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Código do telefone local
        </p>
      </div>

      <div className="flex flex-col items-center min-w-52 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.dates_created_at}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Data de criação
        </p>
      </div>

      <div className="flex flex-col items-center min-w-52 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.dates_confirmed_at}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Data de confirmação
        </p>
      </div>

      <div className="flex flex-col items-center min-w-40 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.dates_canceled_at || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Data de cancelamento
        </p>
      </div>

      <div className="flex flex-col items-center min-w-40 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.dates_warranty_until || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Data de garantia
        </p>
      </div>

      <div className="flex flex-col items-center min-w-40 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.dates_expires_at || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Data de expiração
        </p>
      </div>

      <div className="flex flex-col items-center min-w-40 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.payment_pix_expiration_date || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Data de expiração do pix
        </p>
      </div>

      <div className="flex flex-col items-center min-w-32 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.shipping_name || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Nome de envio
        </p>
      </div>

      <div className="flex flex-col items-center min-w-40 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.shipping_value || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Valor do envio
        </p>
      </div>

      <div className="flex flex-col items-center min-w-40 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.shipping_delivery_time || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Tempo de envio
        </p>
      </div>

      <div className="flex flex-col items-center min-w-40 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.subscription_subscription_code || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Código
        </p>
      </div>

      <div className="flex flex-col items-center min-w-40 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.payment_coupon || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Cupom
        </p>
      </div>

      <div className="flex flex-col items-center min-w-40 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.payment_acquirer_name || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Nome do adquirente
        </p>
      </div>

      <div className="flex flex-col items-center min-w-40 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.payment_acquirer_tid || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Tid do adquirente
        </p>
      </div>

      <div className="flex flex-col items-center min-w-[1700px] ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.payment_pix_qrcode_signature || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Código do Qrcode
        </p>
      </div>
    </li>
  );
};

export default OrderCard;
