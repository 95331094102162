import TwoColumnLayout from "../../layouts/Desktop/TwoColumnLayout";

import Sidebar from "../../components/Sidebar";
import ContentPageContainer from "./components/ContentPageContainer";

const Orders = () => {
    return(
        <TwoColumnLayout
        column1={<Sidebar />}

        column2={<ContentPageContainer />}
      />
    )
}

export default Orders;