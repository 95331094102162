import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={29}
    height={29}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={1}
      width={26.571}
      height={26.791}
      rx={13.286}
      fill="#CBC698"
      stroke="#fff"
      strokeWidth={2}
    />
    <path
      d="M17.86 10.794a.98.98 0 0 1-.864-.534l-.428-.87c-.274-.546-.989-.995-1.596-.995H13.61c-.613 0-1.327.45-1.601.995l-.429.87a.98.98 0 0 1-.863.534 2.244 2.244 0 0 0-2.232 2.393l.31 4.955c.071 1.235.732 2.249 2.375 2.249h6.238c1.643 0 2.297-1.014 2.375-2.25l.31-4.954a2.244 2.244 0 0 0-2.233-2.393m-4.465.75h1.786a.451.451 0 0 1 0 .9h-1.786a.45.45 0 0 1-.447-.45c0-.246.203-.45.447-.45m.893 6.52a2.02 2.02 0 0 1-2.012-2.028c0-1.121.899-2.027 2.012-2.027s2.012.906 2.012 2.027a2.02 2.02 0 0 1-2.012 2.028"
      fill="#fff"
    />
  </svg>
);
export default SVGComponent;
