import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { GlobalContext } from "../../../../context/GlobalContext";
import { patchOdersKiosks } from "../../../../services/Kiosks";
import { formatDate } from "../../../../Utils/formats";

import Checked from "../../../../images/svg/Checked";
import NotChecked from "../../../../images/svg/NotChecked";

const OrderCard = ({ order, setListItens }) => {
  const [idOne, setIdOne] = useState("");
  const {
    currentPage,
    searchingOrdersKiosks,
    startDate,
    endDate,
    setIsLoadingOrdersKiosks,
    setOrdersKiosks,
    isLoadingOrdersKiosks
  } = useContext(GlobalContext);

  useEffect(() => {
    const idDiv1 = uuidv4();
    setIdOne(idDiv1);
    setListItens((prevList) => [...prevList, idDiv1]);
  }, []);

  const updateOrderKiosk = async () => {
    await patchOdersKiosks({
      id: order.id,
      currentPage,
      search: searchingOrdersKiosks,
      startDate,
      endDate,
      setIsLoadingOrdersKiosks,
      setOrdersKiosks,
    });
  };

  return (
    <li
      id={idOne}
      className={`overflow-hidden w-full h-[96px] border-1 border-border rounded-xl px-4 flex items-center gap-3 ${
        order.statusKiosk ? "bg-tertiarycolor" : "bg-white"
      }`}
    >
      <div
        className="flex flex-col items-center max-h-12 min-w-14 gap-1.5"
        onClick={updateOrderKiosk}
      >
        {isLoadingOrdersKiosks && <p>Carregando!</p>}
        {order.statusKiosk ? <Checked /> : <NotChecked />}
      </div>

      <div className="flex flex-col items-center max-h-12 min-w-56 gap-1.5">
        <p className="font-mediump text-sm text-black text-center max-h-12">
          {order?.userModel?.name || "-"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Responsável pela entrega
        </p>
      </div>

      <div className="flex flex-col items-center max-h-12 min-w-[110px] gap-1.5">
        <p className="font-mediump text-sm text-black text-center max-h-12">
          {formatDate(order.date_now) || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Data da compra
        </p>
      </div>

      <div className="flex flex-col items-center max-h-12 min-w-96 gap-1.5">
        <p className="font-mediump text-sm text-black text-center max-h-12">
          {order.product_offer_name || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Nome do produto da oferta
        </p>
      </div>

      <div className="flex flex-col items-center min-w-72 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.contact_name || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Nome do contato
        </p>
      </div>

      <div className="flex flex-col items-center min-w-36 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.contact_doc || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Documento do contato
        </p>
      </div>

      <div className="flex flex-col items-center min-w-96 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.contact_email || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          E-mail do contato
        </p>
      </div>

      <div className="flex flex-col items-center min-w-36 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.contact_phone_number || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Telefone do contato
        </p>
      </div>

      <div className="flex flex-col items-center min-w-28 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.gross || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Bruto
        </p>
      </div>

      <div className="flex flex-col items-center min-w-36 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.product_total_value || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Valor total de produtos
        </p>
      </div>

      <div className="flex flex-col items-center min-w-96 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.product_name || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Nome do produto
        </p>
      </div>

      <div className="flex flex-col items-center min-w-36 ">
        <p className="font-mediump text-sm text-black whitespace-nowrap">
          {order.product_qty || "Não consta"}
        </p>
        <p className="font-mediump text-xs text-grey3 whitespace-nowrap">
          Quantidade do produto
        </p>
      </div>
    </li>
  );
};

export default OrderCard;
