import React, { useState } from "react";

import CloseEyeIcon from "../../images/svg/CloseEyeIcon";
import OpenEyeIcon from "../../images/svg/OpenEyeIcon";

const InputPassword = ({
  label,
  placeholder,
  error,
  register,
  registerKey,
  validate,
  disabled,
  height,
  onBlur,
}) => {
  const [stateEye, setStateEye] = useState(false);

  return (
    <div className="w-full">
      <div className="flex flex-col items-start w-full my-2">
        <label className="text-base mb-2 font-regularp text-black">
          {label}
        </label>
        <input
          className={`
            text-lg font-regularp w-full pl-3 rounded-xl border-1 bg-white placeholder:text-grey2
            ${height ? height : "h-12"} 
            ${error ? "border-red1" : "border-border"}
          `}
          {...register(registerKey, validate)}
          placeholder={placeholder}
          type={stateEye ? "text" : "password"}
          disabled={disabled}
          autoComplete="one-time-code"
          onBlur={onBlur}
        />
        <div className="relative w-full">
          <span className="absolute bottom-10 right-0 mr-3 px-1 rounded-full bg-red-blue-split text-xs font-semiboldc text-red1">
            {error?.message}
          </span>
        </div>
      </div>

      {stateEye ? (
        <div className="relative">
          <div
            className={`absolute right-2.5 bottom-[20px] cursor-pointer bg-white px-1 rounded-full`}
            onClick={() => setStateEye((prevState) => !prevState)}
          >
            <OpenEyeIcon />
          </div>
        </div>
      ) : (
        <div className="relative">
          <div
            className={`absolute right-2.5 bottom-[20px] cursor-pointe bg-white px-1 rounded-full`}
            onClick={() => setStateEye((prevState) => !prevState)}
          >
            <CloseEyeIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default InputPassword;
