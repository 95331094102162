import * as React from "react";

const BagIconPageNotSelected = (props) => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.4243 7.52814C18.9491 6.97791 18.2571 6.66945 17.5318 6.66945H17.4568C17.0399 2.92622 13.8553 0 10.0037 0C6.15207 0 2.95907 2.92622 2.54223 6.66945H2.50055C1.77524 6.66945 1.08329 6.98624 0.608092 7.52814C0.132894 8.07837 -0.0755265 8.80367 0.0245152 9.52063L0.908217 15.7065C1.25836 18.1576 3.38425 20 5.86028 20H14.1721C16.6481 20 18.774 18.1576 19.1241 15.7065L20.0078 9.52063C20.1079 8.80367 19.8995 8.07837 19.4243 7.52814ZM10.0037 1.66736C12.9382 1.66736 15.3726 3.84327 15.7811 6.66945H4.22627C4.63477 3.84327 7.06912 1.66736 10.0037 1.66736ZM18.3572 9.2872L17.4735 15.4731C17.24 17.1071 15.8228 18.3326 14.1721 18.3326H5.86028C4.20959 18.3326 2.79234 17.0988 2.5589 15.4731L1.6752 9.2872C1.64186 9.04544 1.70855 8.81201 1.86695 8.62026C2.02535 8.43685 2.25044 8.32847 2.50055 8.32847H17.5318C17.7736 8.32847 17.9987 8.42851 18.1571 8.61192C18.3155 8.79533 18.3905 9.0371 18.3488 9.27887L18.3572 9.2872Z"
      fill="#787486"
    />
  </svg>
);
export default BagIconPageNotSelected;
