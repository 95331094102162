import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";

import { GlobalContext } from "../../../../context/GlobalContext.jsx";
import { patchUser, postUser } from "../../../../services/Users/index.js";

import ProfilePicture from "../../../../components/ProfilePicture/index.jsx";
import InputPassword from "../../../../components/InputPassword/index.js";
import Button from "../../../../components/Button/index.jsx";
import Input from "../../../../components/Input/index.jsx";

import UserProfile from "../../../../images/svg/UserProfile.js";
import ChangeImage from "../../../../images/svg/ChangeImage.js";
import Close from "../../../../images/svg/Close.js";


const ModalAddOrEdit = ({ show, close, selectedUser }) => {
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const { setUsers, isLoadingUsers, setIsLoadingUsers } = useContext(GlobalContext);

  const [image, setImage] = useState();
  const [base64Image, setBase64Image] = useState();
  const [role, setRole] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setBase64Image(file);
      setImage(URL.createObjectURL(file));
    }
  };

  const createUser = async ({ name, email, password }) => {
    if (!name || !email || !role || !password) {
      return false;
    }

    await postUser({ name, email, password, role, base64Image, setUsers, close, resetData, setIsLoadingUsers })
  };

  const editUser = async ({ name, email, password }) => {
    if (!name || !email || !role) {
      return false;
    }

    patchUser({ name, email, password, role, base64Image, image, selectedUser, setUsers, resetData, setIsLoadingUsers })
  };

  const resetData = () => {
    close();
    reset();
    setRole("");
    setImage("");
    setBase64Image("");
  };

  useEffect(() => {
    if (selectedUser?.name) {
      setImage(selectedUser?.image);
      setValue("name", selectedUser.name);
      setValue("email", selectedUser.mail);
      setRole(selectedUser.role);
    } else {
      reset();
      setRole("");
      setImage("");
    }
  }, [selectedUser]);

  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={() => resetData()}
      scrollable={true}
      animation
      className="h-full overflow-y-visible"
    >
      <form
        onSubmit={
          selectedUser ? handleSubmit(editUser) : handleSubmit(createUser)
        }
      >
        <Modal.Header className="bg-white border-border">
          <Modal.Title className="flex justify-between items-center w-full gap-4 px-3">
            <div className="flex items-center w-full gap-4">
              <div onClick={() => resetData()} className="cursor-pointer">
                <Close width={18} height={18} />
              </div>
              <p className="text-blac font-mediump text-2xl">
                {selectedUser ? "Editar usuário" : "Criar usuário"}
              </p>
            </div>
            <Button
              type="submit"
              name={"Salvar"}
              backgroundColor={"bg-primarycolor"}
              width={"w-40"}
              height={"h-10"}
              disabled={isLoadingUsers}
              textColor={"text-white"}
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="flex flex-col items-center justify-center gap-4 bg-white pt-2 pb-4 px-3 h-[460px]">
          <div className="flex flex-col items-center">
            <label
              htmlFor="fileInput"
              className="cursor-pointer border-2 border-grey rounded-full"
            >
              {image ? (
                <ProfilePicture width={"w-36"} height={"h-36"} url={image} />
              ) : (
                <div>
                  <UserProfile width={131} height={131} />
                </div>
              )}
              <div className="relative bottom-8">
                <div className="absolute right-3">
                  <ChangeImage />
                </div>
              </div>
            </label>
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              className="hidden"
              onChange={handleFileChange}
            />
          </div>

          <div className="flex flex-col items-center justify-center w-full px-4 gap-3">
            <div className="flex flex-row justify-between w-full gap-5 items-center">
              <div className="w-full lg:w-48%">
                <Input
                  label="Nome do usuário"
                  registerKey={"name"}
                  register={register}
                  placeholder="Insira seu nome"
                  error={errors.name}
                  validate={{
                    required: "Campo obrigatório!",
                  }}
                />
              </div>
              <div className="w-full lg:w-48%">
                <Input
                  label="E-mail"
                  placeholder="Insira seu e-mail"
                  registerKey={"email"}
                  register={register}
                  error={errors.email}
                  validate={{
                    required: "Campo obrigatório!",
                    pattern: {
                      value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      message: "E-mail inválido!",
                    },
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row justify-between w-full gap-5 items-center">
              <div className="w-full lg:w-48%">
                <InputPassword
                  label="Senha de acesso"
                  placeholder="8+ Caracteres"
                  registerKey={"password"}
                  register={register}
                  error={errors.password}
                  validate={{
                    required: selectedUser?.name ? false : "Campo obrigatório!",
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#"{}^$!%*?&-_+=\[\]\/.';|])[A-Za-z\d@#"{}^$!%*?&-_+=\[\]\/.';|]{8,}$/,
                      message:
                        "1 maiúscula, 1 minúscula, 1 número e 1 símbolo.",
                    },
                    minLength: {
                      value: 8,
                      message: "Mínimo 8 caracteres!",
                    },
                  }}
                />
              </div>

              <div className="w-full lg:w-48%">
                <label className="font-regularp mb-2">Permissão</label>
                <select
                  value={role}
                  required={true}
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                  className={`placeholder:text-gray-200 font-regularp text-black text-base w-full px-2 h-12 rounded-xl bg-white border-1 border-border`}
                >
                  <option value="" disabled className="font-regularp">
                    Selecione uma permissão
                  </option>
                  <option value="Administrador geral" className="font-regularp">
                    Administrador geral
                  </option>
                  <option value="Vendedor(a)" className="font-regularp">
                    Vendedor(a)
                  </option>
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  );
};

export default ModalAddOrEdit;
