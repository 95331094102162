import secureLocalStorage from "react-secure-storage";
import { jwtDecode } from "jwt-decode";

import api from "../api";

export const postLogin = async ({ email, password, setIsLoadingOrders, setUser, navigate }) => {
  setIsLoadingOrders(true);

  try {
    const { data } = await api.post('/auth/login', { mail: email, password });

    const decodedToken = jwtDecode(data.token);
    secureLocalStorage.setItem('@USER_TOKEN', data.token);
    secureLocalStorage.setItem('@USER_IMAGE/NAME', data.user);

    if (decodedToken) setUser(decodedToken?.name);

    if (data.user?.role === "Administrador geral") {
      navigate('/orders');
    } else if (data.user?.role === "Vendedor(a)") {
      navigate('/kiosks');
    }

  } catch (error) {
    setUser(null);
  } finally {
    setIsLoadingOrders(false);
  }
};

export const postAutoLogin = async ({ navigate }) => {
  const token = secureLocalStorage.getItem("@USER_TOKEN");

  if (token) {
    const { data } = await api.get("/user/token", {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (data?.user) {
      if (data.user?.role === "Administrador geral") {
        navigate("/orders");
      } else if (data.user?.role === "Vendedor(a)") {
        navigate("/kiosks");
      }
    }
  }
}
