import * as React from "react";

const ArrowDownIcon = (props) => (
  <svg
    width={9}
    height={5}
    viewBox="0 0 9 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.461317 1.58529C0.296291 1.34752 0.296291 1.03226 0.461317 0.794488C0.737154 0.397054 1.3249 0.397054 1.60074 0.794488L2.7599 2.46465C3.59697 3.67072 5.38058 3.67072 6.21765 2.46465L7.37682 0.794488C7.65265 0.397054 8.2404 0.397054 8.51623 0.794488C8.68126 1.03226 8.68126 1.34752 8.51623 1.58529L7.39213 3.20495C5.98641 5.23034 2.99114 5.23034 1.58543 3.20494L0.461317 1.58529Z"
      fill="black"
    />
  </svg>
);
export default ArrowDownIcon;
