import { ToastContainer } from "react-toastify";
import ReactDOM from "react-dom/client";
import React from "react";

import reportWebVitals from "./reportWebVitals";
import AppStack from "./routes/AppStack";

import GlobalProvider from "./context/GlobalContext";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GlobalProvider>
    <ToastContainer />
    <AppStack />
  </GlobalProvider>
);

reportWebVitals();
