import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#fff">
      <path d="M21.083 10.084H19.25V8.25a.917.917 0 0 0-1.833 0v1.834h-1.834a.917.917 0 0 0 0 1.833h1.834v1.833a.917.917 0 0 0 1.833 0v-1.833h1.833a.916.916 0 1 0 0-1.833M8.25 11a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11m0-9.167a3.667 3.667 0 1 1 0 7.334 3.667 3.667 0 0 1 0-7.334m0 11.001A8.26 8.26 0 0 0 0 21.084a.917.917 0 0 0 1.833 0 6.417 6.417 0 0 1 12.834 0 .916.916 0 1 0 1.833 0 8.26 8.26 0 0 0-8.25-8.25" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
