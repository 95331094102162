import React from 'react';

const TwoColumnLayout = ({ column1, column2 }) => {
    return (
        <div className="flex w-full h-screen items-center m-auto">
            <div className="flex w-[230px] min-h-screen overflow-y-auto">
                {column1}
            </div>

            <div className="flex justify-center m-auto h-screen px-[5%] w-full">
                {column2}
            </div>
        </div>
    );
};

export default TwoColumnLayout;
