import * as React from "react";

const PreviousPageIcon = (props) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <rect width={40} height={40} fill="url(#pattern0_7430_18)" />
    <defs>
      <pattern
        id="pattern0_7430_18"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use xlinkHref="#image0_7430_18" transform="scale(0.0104167)" />
      </pattern>
      <image
        id="image0_7430_18"
        width={96}
        height={96}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAACs0lEQVR4nO2dPW6TQRCG3UCL4BiEE3AAGijJAZgxkbgLPyGIIgdg3+iTshMkOkINBVTAQSARFBgRrSwSAUGYwju7s+8jTe95n9lZW7K+bzYjhBBCCCGEEEIIIWRF9vfkmiXdzpAPGXps0B+RK0OPS6+l59K726BM0+bFDH1qkO/eoZhXJV1Ykicli+rhG+SVewBoo3LSw6oSlpPv37g1VBm6U3PnL7wbttYq6eJ5mm+sXYBBHrs3izYrQx6uXUBO+tG7UWu1kr5fuwBLcuTeKNqsnPTz+gU00Kg1XBQACnCfQuMJ8A/CuIL8wzCH4h0ACnCfQuMJ8A/CuII8g5C3GfNb5YcR7wCH8F88u3d5+aPx7vXaEga/hOU0/LPPW1fCwALkj/A9JAwqQP4afm0JwwnI0DfTtHVplc++n+QmBVSe/J8USUUWBQQO38ZZQdJk+IMIkGbDH0CANB1+cAHSfPiBBUgX4QcVIN2EH1CAdBV+MAHSXfiBBEiX4QcRIN2GH0CAdB1+5wKk+/D7FpD03TTplVUFWJLX3mHHEoAYEvoWgP4l9C8AfUuIIQD9SogjAH1KiCUA/UmIJwB9SYgpAP1IiCsAfUiILQDtS4gvAG1LGEMA2pUwjgC0KWEsAfi/P+cepPltCnA6CRlyw5J+oQCHdZQrhT/kCrJ/SKgZPgXgVwm1w6cAnF3MeU83Dfq19ikcewXBvygAFOA+hcYT4B+EcQX5h2EOxTsAFOA+hcYT4B+ERV1BHs/gsU4qQz/VEMBHF8Px0cUZ+sh70qzRypD7axdQHtHOx9fredO/OJjuXJ3VoLy2w3varLVKuj2r+v6YpIfuTaONyklf7u5uXagm4FQCdGfkdZSTfCuTXz383++E8uaI8g1giHcLJDla9qoPqu18QgghhBBCCCGEEDKLwAkWHRdvh6/lKwAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
);
export default PreviousPageIcon;
