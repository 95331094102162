import { toast } from "react-toastify";

import api from "../api";
import { fileToBase64 } from "../../Utils/formatBase64";

export const postUser = async ({ name, email, password, role, base64Image, setUsers, close, resetData, setIsLoadingUsers }) => {
  setIsLoadingUsers(true);

  api
    .post("/user", {
      name,
      mail: email,
      role,
      password,
      image: base64Image && await fileToBase64(base64Image),
    })
    .then((res) => {
      toast.success(res.data.message);
      getUsers({ setUsers, setIsLoadingUsers });
      close();
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    })
    .finally(() => {
      resetData();
      setIsLoadingUsers(false);
    });
};

export const patchUser = async ({ name, email, password, role, base64Image, image, selectedUser, setUsers, resetData, setIsLoadingUsers }) => {
  setIsLoadingUsers(true);

  api
    .patch("/user", {
      name,
      mail: email,
      role,
      password,
      image: base64Image ? await fileToBase64(base64Image) : image,
      id: selectedUser?.id,
    })
    .then((res) => {
      toast.success(res.data.message);
      getUsers({ setUsers, setIsLoadingUsers });
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    })
    .finally(() => {
      resetData();
      setIsLoadingUsers(false);
    });
};

export const destroyUser = async ({ id, setUsers, setIsLoadingUsers, close }) => {
  try {
    setIsLoadingUsers(true);

    const response = await api.delete(`/user/${id}`);
    toast.success(response.data.message);
    getUsers({ setUsers, setIsLoadingUsers });
    close();
  } catch (error) {
    toast.error(error.response.data.message);
  } finally {
    setIsLoadingUsers(false)
  }
};

export const getUsers = async ({ setUsers, setIsLoadingUsers }) => {
  setIsLoadingUsers(true);

  try {
    const { data } = await api.get(`/user`);

    setUsers(data);

  } catch (error) {
    toast.error(error.response?.data.error)
  } finally {
    setIsLoadingUsers(false);
  }
};
