import * as React from "react";
const LogoutIcon = (props) => (
  <svg
    width={19}
    height={19}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.10249 16.5722H3.94583C3.52723 16.5722 3.12577 16.4059 2.82978 16.1099C2.53378 15.8139 2.36749 15.4125 2.36749 14.9939V3.94552C2.36749 3.52692 2.53378 3.12547 2.82978 2.82947C3.12577 2.53348 3.52723 2.36719 3.94583 2.36719H7.10249"
      stroke="#787486"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6266 13.4161L16.5725 9.47025L12.6266 5.52441"
      stroke="#787486"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5725 9.46973H7.10248"
      stroke="#787486"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default LogoutIcon;
