import ReactLoading from 'react-loading';

const Button = ({
  name,
  disabled,
  onClick,
  type,
  width,
  height,
  backgroundColor,
  border,
  textColor,
  fontSize,
  padding,
}) => {
  const buttonStyle = `
    ${textColor || 'text-white'} 
    font-mediump
    text-base
    ${fontSize || 'xl:text-lg'}
    ${border}
    ${width} 
    2xl:w-${parseFloat(width?.replace(/\D/g, '')) + 12} 
    ${height} 
    2xl:h-${parseFloat(height?.replace(/\D/g, '')) + 4} 
    rounded-full 
    ${padding}
  `;

  const disabledStyle = disabled ? 'bg-grey1' : backgroundColor;

  return (
    <button
      className={`${buttonStyle} ${disabledStyle} flex justify-center items-center`}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {disabled ?
        <ReactLoading
          type={'spin'}
          color={'white'}
          height={20}
          width={20}
        /> : name}
    </button>
  );
};

export default Button;
