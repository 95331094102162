import TwoColumnLayout from "../../layouts/Desktop/TwoColumnLayout";

import Sidebar from "../../components/Sidebar";
import ContentPageContainer from "./components/ContentPageContainer";

const Kiosk = () => {
    return(
        <TwoColumnLayout
        column1={<Sidebar />}
        column2={<ContentPageContainer />}
      />
    )
}

export default Kiosk;