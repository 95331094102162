import React from 'react';

import UserProfile from "../../../../images/svg/UserProfile"
import ThreePoints from "../../../../images/svg/ThreePoints"

const UserCard = ({ card, selectUserAndOpenModalAction }) => {
  return (
    <li className="flex justify-between w-full h-[70px] border-1 border-border rounded-xl px-4 items-center gap-3 bg-white">
      <div className='w-[8%]'>
        <div className='flex justify-center items-center border-1 border-primarycolor rounded-full w-12 h-12 object-contain'>
          {card?.image ? <img src={card?.image} className='border-1 border-primarycolor rounded-full w-12 h-12 object-cover'/> : <UserProfile />}
        </div>
      </div>
      <div className='w-[30%] text-left font-mediump text-base'>{card?.name}</div>
      <div className='w-[40%] text-left font-mediump text-base'>{card?.mail}</div>
      <div className='w-[20%] text-left font-mediump text-base'>{card?.role}</div>
      <div className='w-[2%]' onClick={selectUserAndOpenModalAction}>
        <ThreePoints />
      </div>
    </li>
  );
};

export default UserCard;
