import { toast } from "react-toastify";
import api from "../api";

export const getOrdersKiosks = async ({ currentPage, search, startDate, endDate, setIsLoadingOrdersKiosks, setOrdersKiosks }) => {
  try {
    setIsLoadingOrdersKiosks(true);
    const { data } = await api.get(`/orders/kiosks/?page=${currentPage}&search=${search}&startDate=${startDate ? startDate : 0}&endDate=${endDate ? endDate : 0}`);

    setOrdersKiosks(data.orders);
  } catch (error) {
    toast.error(error.response?.data.messsage || 'Erro ao buscar os pedidos')
  } finally {
    setIsLoadingOrdersKiosks(false);
  }
};

export const patchOdersKiosks = async ({
  id,
  currentPage,
  search,
  startDate,
  endDate,
  setIsLoadingOrdersKiosks,
  setOrdersKiosks, }) => {
  try {
    setIsLoadingOrdersKiosks(true);
    const response = await api.patch(`/orders/status`, { id });
    toast.success(response.data.message);
  } catch (error) {
    toast.error(error.response?.data.messsage || 'Erro ao editar o pedido')
  } finally {
    getOrdersKiosks({ currentPage, search, startDate, endDate, setIsLoadingOrdersKiosks, setOrdersKiosks })
  }
}
