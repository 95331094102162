import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.833 11.667A.833.833 0 0 1 5 12.5h-.833a.833.833 0 1 1 0-1.667H5a.833.833 0 0 1 .833.834m3.334-.834h-.834a.833.833 0 1 0 0 1.667h.834a.833.833 0 1 0 0-1.667M5 14.167h-.833a.834.834 0 0 0 0 1.666H5a.834.834 0 0 0 0-1.666m4.167 0h-.834a.834.834 0 0 0 0 1.666h.834a.834.834 0 0 0 0-1.666M5 4.167h-.833a.833.833 0 1 0 0 1.666H5a.833.833 0 1 0 0-1.666m4.167 0h-.834a.833.833 0 1 0 0 1.666h.834a.833.833 0 1 0 0-1.666M5 7.5h-.833a.833.833 0 1 0 0 1.667H5A.833.833 0 0 0 5 7.5m4.167 0h-.834a.833.833 0 0 0 0 1.667h.834a.833.833 0 0 0 0-1.667M20 8.333v7.5A4.17 4.17 0 0 1 15.833 20H4.167A4.17 4.17 0 0 1 0 15.833V4.167A4.17 4.17 0 0 1 4.167 0h5a4.17 4.17 0 0 1 4.166 4.167h2.5A4.17 4.17 0 0 1 20 8.333m-15.833 10h7.5V4.167a2.5 2.5 0 0 0-2.5-2.5h-5a2.5 2.5 0 0 0-2.5 2.5v11.666a2.5 2.5 0 0 0 2.5 2.5m14.166-10a2.5 2.5 0 0 0-2.5-2.5h-2.5v12.5h2.5a2.5 2.5 0 0 0 2.5-2.5zm-2.5 2.5a.833.833 0 1 0 0 1.666.833.833 0 0 0 0-1.666m0 3.334a.833.833 0 1 0 0 1.665.833.833 0 0 0 0-1.665m0-6.667a.833.833 0 1 0 0 1.666.833.833 0 0 0 0-1.666"
      fill="#fff"
    />
  </svg>
);
export default SVGComponent;
