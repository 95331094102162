import Modal from 'react-bootstrap/Modal';

import Close from "../../images/svg/Close"
import Button from "../../components/Button"


const ModalDelete = ({ show, close, handleDelete, isLoading, name, title }) => {
    return (
        <Modal
            centered
            show={show}
            onHide={close}
            scrollable={true}
            animation
        >
            <Modal.Header className='bg-white border-border'>
                <Modal.Title className='w-full flex items-center justify-between'>
                    <div className='flex items-center gap-3'>
                        <div onClick={close} className='cursor-pointer'>
                            <Close width={18} height={18} />
                        </div>
                        <p className='text-black font-semiboldp text-xl'>Excluir {title}</p>
                    </div>
                    <Button name="Excluir" width={"w-32"} height={"h-10"} disabled={isLoading} textColor={"text-red1"} backgroundColor={"bg-red4"} border={"border-none"} onClick={() => { handleDelete(close); }} />
                </Modal.Title>
            </Modal.Header>
            
            <Modal.Body className='flex justify-center items-center bg-white px-4 pt-0 h-60'>
                <p className='text-black font-mediump text-center text-base xl:text-lg 2xl:text-xl'>Tem certeza que deseja excluir {title}<p className='font-semiboldp'> {name} ?</p> </p>
            </Modal.Body>
        </Modal>
    )
}

export default ModalDelete