export const fileToBase64 = (file) =>new Promise((resolve, reject) => {
  
    if (!file) {
      reject(new Error("Nenhum arquivo fornecido."));
      return;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1];
      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });

