import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { GlobalContext } from "../../../context/GlobalContext.jsx";
import { postAutoLogin, postLogin } from "../../../services/Auth/index.js";

import SingleColumnLayout from "../../../layouts/Desktop/SingleColumnLayout.jsx";

import InputPassword from "../../../components/InputPassword";
import Button from "../../../components/Button";
import Input from "../../../components/Input";

import Logo from "../../../images/svg/Logo.js";

const Login = () => {
  const navigate = useNavigate();

  const { setUser, isLoadingOrders, setIsLoadingOrders } =
    useContext(GlobalContext);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const getLogin = async ({ email, password }) => {
    await postLogin({ email, password, setIsLoadingOrders, setUser, navigate });
  };

  useEffect(() => {
    postAutoLogin({ navigate });
  }, []);

  return (
    <SingleColumnLayout>
      <div className="flex flex-col content-center h-screen w-screen justify-center items-center">
        <div className="flex flex-col items-center">
          <div className="mb-10">
            <Logo width={125} height={125} />
          </div>

          <div>
            <h1 className="font-mediump text-black text-2xl mb-10">
              Dados de acesso
            </h1>
          </div>
        </div>

        <div style={{ maxWidth: "418px", width: "100%" }}>
          <form
            onSubmit={handleSubmit(getLogin)}
            className="flex flex-col items-center"
            autoComplete="off"
          >
            <Input
              label="E-mail"
              placeholder="Insira seu e-mail"
              registerKey={"email"}
              register={register}
              error={errors.email}
              validate={{
                required: "Campo obrigatório!",
                pattern: {
                  value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                  message: "E-mail inválido!",
                },
              }}
            />

            <InputPassword
              label="Senha"
              placeholder="8+ Caracteres"
              registerKey={"password"}
              register={register}
              error={errors.password}
              validate={{
                required: "Campo obrigatório!",
              }}
            />

            <div className="w-full pt-2">
              <p
                className="textPrimaryDetails text-right"
                onClick={() => navigate("/lostpassword")}
              >
                Esqueceu a senha?
              </p>
            </div>

            <div className="flex justify-center pt-4 w-full mt-10">
              <Button
                name={isLoadingOrders ? "Carregando" : "Entrar"}
                width="w-100%"
                height={"h-12"}
                textColor={"text-white"}
                backgroundColor="bg-primarycolor"
                disabled={isLoadingOrders}
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </SingleColumnLayout>
  );
};

export default Login;
