import React from "react";

import PreviousPageIcon from "../../../../images/svg/Pagination/PreviousPageIcon";
import NextPageIcon from "../../../../images/svg/Pagination/NextPageIcon";

const Pagination = ({ currentPage, onPageChange }) => {
  const adjacentPages = 1;
  const totalPages = 10;

  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="flex items-center justify-center gap-5 mb-30">
      {currentPage > 1 && (
        <button
          onClick={() => onPageChange(currentPage - 1)}
          className="w-30 h-30 bg-white rounded-full flex items-center justify-center"
        >
          <PreviousPageIcon />
        </button>
      )}

      {currentPage > 2 && (
        <>
          <button
            onClick={() => onPageChange(1)}
            className="w-30 h-30 bg-white rounded-full flex items-center justify-center"
          >
            1
          </button>
          {currentPage > 2 && <span>...</span>}
        </>
      )}

      {pageNumbers.map((page) => {
        if (
          page < currentPage - adjacentPages ||
          page > currentPage + adjacentPages
        ) {
          return null;
        }
        return (
          <button
            key={page}
            onClick={() => onPageChange(page)}
            className={`w-8 h-8 ${
              currentPage === page ? "bg-primarycolor text-white" : "bg-white"
            } rounded-full flex items-center justify-center`}
          >
            {page}
          </button>
        );
      })}

      {currentPage < totalPages - 1 && (
        <>
          {currentPage < totalPages - 1 && <span>...</span>}
          <button
            onClick={() => onPageChange(totalPages + currentPage)}
            className="w-30 h-30 bg-white rounded-full  flex items-center justify-center"
          >
            {totalPages + currentPage}
          </button>
        </>
      )}

      {currentPage < totalPages && (
        <button
          onClick={() => onPageChange(currentPage + 1)}
          className="w-30 h-30 bg-white rounded-full flex items-center justify-center"
        >
          <NextPageIcon />
        </button>
      )}
    </div>
  );
};

export default Pagination;
