import React, { useContext } from 'react';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

import 'react-datepicker/dist/react-datepicker.css';

import { GlobalContext } from '../../context/GlobalContext';

import CalendaryIcon from '../../images/svg/InputDate/CalendaryIcon';


const InputDate = ({ height, width, bottom }) => {
  const { startDate, endDate, setStartDate, setEndDate } = useContext(GlobalContext);

  const handleDateChange = async (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const formatDateForPlaceholder = (date) => {
    return date ? format(date, 'dd/MM/yyyy') : '';
  };

  const placeholderText = startDate && endDate ?
    `${formatDateForPlaceholder(
      startDate
    )} - ${formatDateForPlaceholder(endDate)}` : "Selecione uma data"

  const minDate = new Date('2024-04-05');

  return (
    <div className="relative z-0">
      <DatePicker
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          handleDateChange(update);
        }}
        dateFormat="dd/MM/yyyy"
        selected={startDate}
        minDate={minDate}
        selectsRange
        withPortal
        className={`w-[230px] text-sx text-grey3 font-regularc rounded-full ${height} ${width} px-3 bg-white border-1 border-border`}
        placeholderText={placeholderText}
      />
      <div className={`absolute right-3 ${bottom} 2xl:right-3`}>
        <CalendaryIcon />
      </div>
    </div>
  );
};

export default InputDate;
