import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 0.72 0.72"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M.601.09a.03.03 0 0 0-.022.009L.518.16A.349.349 0 0 0 .36.12a.41.41 0 0 0-.251.1.511.511 0 0 0-.076.075.252.252 0 0 0-.022.032C.005.337 0 .344 0 .36s.005.023.011.033l.022.032A.511.511 0 0 0 .109.5a.41.41 0 0 0 .04.029l-.05.05a.03.03 0 0 0 0 .042.03.03 0 0 0 .042 0L.202.56C.249.584.303.6.36.6A.41.41 0 0 0 .611.5.511.511 0 0 0 .687.425.184.184 0 0 0 .709.393C.715.383.72.377.72.36S.715.337.709.327A.252.252 0 0 0 .687.295.511.511 0 0 0 .611.22a.554.554 0 0 0-.04-.029l.05-.05a.03.03 0 0 0 0-.042.03.03 0 0 0-.02-.009zM.36.18c.039 0 .077.01.112.025l-.11.11A.06.06 0 0 1 .36.3.06.06 0 0 1 .395.245.12.12 0 0 0 .36.24a.12.12 0 0 0-.002 0A.12.12 0 0 0 .24.36a.12.12 0 0 0 .017.061L.192.486A.468.468 0 0 1 .146.453.456.456 0 0 1 .079.387.2.2 0 0 1 .062.363L.061.36.062.357A.2.2 0 0 1 .079.333.456.456 0 0 1 .146.267.356.356 0 0 1 .36.18zm.168.054c.016.01.032.021.046.033a.456.456 0 0 1 .067.066l.017.024.001.003-.001.003a.2.2 0 0 1-.017.024.456.456 0 0 1-.067.066A.356.356 0 0 1 .36.54.286.286 0 0 1 .248.515L.3.463A.12.12 0 0 0 .36.48.12.12 0 0 0 .48.36.12.12 0 0 0 .475.325.06.06 0 0 1 .42.36.06.06 0 0 1 .404.358L.528.234z"
            style={{
                fill: "#000",
                stroke: "none",
                strokeWidth: 2,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 4,
                strokeDasharray: "none",
            }}
        />
    </svg>
);
export default SVGComponent;
