import React, { useContext } from 'react';

import { GlobalContext } from '../../../../context/GlobalContext';

import InputSearch from '../../../../components/InputSearch';
import Button from '../../../../components/Button';

const PageController = ({ setOpenModalAddOrEdit }) => {
  const { preparingDownload } = useContext(GlobalContext);

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center gap-3">
        <InputSearch
          width={'w-[322px]'}
          height={'h-11'}
          bottom={'bottom-3.5'}
        />
      </div>

      <Button
        name={'Novo usuário'}
        backgroundColor={'bg-primarycolor'}
        disabled={preparingDownload}
        textColor={'text-white'}
        height={'h-11'}
        fontSize={'text-sm'}
        width={'min-w-[200px]'}
        onClick={setOpenModalAddOrEdit}
      />
    </div>
  );
};

export default PageController;
