import React, { useContext, useEffect, useMemo, useState } from "react";

import { GlobalContext } from "../../../../context/GlobalContext";
import { destroyUser, getUsers } from "../../../../services/Users";

import Header from "../../../../components/Header";
import ModalActions from "../../../../components/ModalActions";
import ModalDelete from "../../../../components/ModalDelete";

import PageController from "../PageController";

import UserCard from "../UserCard";
import ModalAddOrEdit from "../../components/ModalAddOrEdit";

const ContentPageContainer = () => {
  const { setUsers, users, user, searchingUsuario, isLoadingUsers, setIsLoadingUsers } = useContext(GlobalContext);

  const [selectedUser, setSelectedUser] = useState(null);
  const [openModalActions, setOpenModalActions] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalAddOrEdit, setOpenModalAddOrEdit] = useState(false);

  useEffect(() => {
    getUsers({ setUsers, setIsLoadingUsers })
  }, []);

  const usersFiltered = useMemo(() => {
    return users?.filter((e) =>
      e?.name.toLowerCase().includes(searchingUsuario.toLowerCase())
    );
  }, [users, searchingUsuario]);

  return (
    <div className="w-full">
      <Header pageName="Gerenciar acessos" user={user} />

      <main>
        <PageController
          setOpenModalAddOrEdit={() => setOpenModalAddOrEdit(true)}
        />

        <ul className="flex flex-col gap-3 mt-5 pb-[100px]">
          <li className="flex justify-between w-full h-[70px] border-1 border-border rounded-xl px-4 items-center gap-3">
            <div className="w-[8%] h-14" />
            <div className="w-[30%] text-left text-grey3 font-mediump text-base">
              Nome de usuário
            </div>
            <div className="w-[40%] text-left text-grey3 font-mediump text-base">
              E-mail
            </div>
            <div className="w-[20%] text-left text-grey3 font-mediump text-base">
              Tipo de usuário
            </div>
            <div className="w-[2%] h-14" />
          </li>
          {usersFiltered?.length ? (
            usersFiltered
              ?.filter((e) =>
                e?.name.toLowerCase().includes(searchingUsuario.toLowerCase())
              )
              ?.map((user, i) => (
                <UserCard
                  key={i}
                  card={user}
                  selectUserAndOpenModalAction={() => {
                    setSelectedUser(user);
                    setOpenModalActions(true);
                  }}
                />
              ))
          ) : (
            <li className="flex justify-center w-full h-[120px] border-1 border-border rounded-xl px-4 items-center gap-3">
              <p className="text-grey3 font-mediump text-base">
                {selectedUser || searchingUsuario
                  ? "Nenhum usuário encontrado"
                  : "Carregando..."}
              </p>
            </li>
          )}
        </ul>
      </main>

      <ModalActions
        show={openModalActions}
        close={() => {
          setOpenModalActions(false);
        }}
        title={"acesso"}
        showModalDelete={() => setOpenModalDelete(true)}
        showModalEdit={() => setOpenModalAddOrEdit(true)}
      />

      <ModalDelete
        show={openModalDelete}
        close={() => {
          setOpenModalDelete(false);
          setSelectedUser(null);
        }}
        handleDelete={(close) => destroyUser({ id: selectedUser.id, setUsers, setIsLoadingUsers, close })}
        title={"acesso"}
        isLoading={isLoadingUsers}
        name={selectedUser?.name}
      />

      <ModalAddOrEdit
        show={openModalAddOrEdit}
        close={() => {
          setOpenModalAddOrEdit(false);
          setSelectedUser(null);
        }}
        selectedUser={selectedUser}
      />
    </div>
  );
};

export default ContentPageContainer;
