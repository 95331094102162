import React, { createContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";

export const GlobalContext = createContext({});

function GlobalProvider({ children }) {
  const [searchingUsuario, setSearchingUsuario] = useState("");
  const [searchingOrders, setSearchingOrders] = useState("");
  const [searchingOrdersKiosks, setSearchingOrdersKiosks] = useState("");

  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [orders, setOrders] = useState(null);
  const [ordersKiosks, setOrdersKiosks] = useState(null);

  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);

  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [isLoadingOrdersKiosks, setIsLoadingOrdersKiosks] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [preparingDownload, setIsPreparingDownload] = useState(false);
  const [resultFile, setResultFile] = useState({
    filename: "report.csv",
    headers: "",
    data: "",
  });

  const logout = () => {
    secureLocalStorage.removeItem("@USER_TOKEN");
    setUser(null);
  };

  const dowloadExcel = async (type) => {
    setIsPreparingDownload(true);

    try {
      const headers = [
        { label: "Nome da oferta", key: "product_offer_name" },
        { label: "Nome marketplace", key: "marketplace_name" },
        { label: "Id marketplace", key: "marketplace_id" },
        { label: "Status", key: "status" },
        { label: "Tipo", key: "type" },
        { label: "Método de pagamento", key: "method_payment" },
        { label: "Quantidade de parcelas", key: "installments_qty" },
        { label: "Tipo de moeda", key: "currency" },
        { label: "Valor bruto", key: "gross" },
        { label: "Valor marketplace", key: "marketplace_value" },
        { label: "Valor afiliado", key: "affiliate_value" },
        { label: "Valor de envio", key: "shipping_value" },
        { label: "Rede de pagamento", key: "payment_net" },
        { label: "Valor total do produto", key: "product_total_value" },
        {
          label: "Valor de desconto do produto",
          key: "payment_discount_value",
        },
        {
          label: "Valor de imposto",
          key: "tax_value",
        },
        {
          label: "Valor das parcelas",
          key: "installments_value",
        },
        {
          label: "ID do produto",
          key: "product_id",
        },
        {
          label: "Nome do produto",
          key: "product_name",
        },
        {
          label: "Quantidade do produto",
          key: "product_qty",
        },
        {
          label: "Motivo de reembolso",
          key: "payment_refund_reason",
        },
        {
          label: "Nome da empresa",
          key: "contact_company_name",
        },
        {
          label: "Nome do contato",
          key: "contact_name",
        },
        {
          label: "Documento do contato",
          key: "contact_doc",
        },
        {
          label: "E-mail do contato",
          key: "contact_email",
        },
        { label: "Endereço do contato", key: "contact_address" },
        { label: "Número do endereço", key: "contact_address_number" },
        { label: "Numero do contato", key: "contact_phone_number" },
        { label: "Complemento", key: "contact_address_comp" },
        {
          label: "Bairro",
          key: "contact_address_district",
        },
        {
          label: "Cidade",
          key: "contact_address_city",
        },
        {
          label: "Estado",
          key: "contact_address_state",
        },
        {
          label: "País",
          key: "contact_address_country",
        },
        {
          label: "Cep",
          key: "contact_address_zip_code",
        },
        {
          label: "Código local",
          key: "contact_address_phone_local_code",
        },
        {
          label: "Data de criação",
          key: "dates_created_at",
        },
        {
          label: "Data de confirmação",
          key: "dates_confirmed_at",
        },
        {
          label: "Data de cancelamento",
          key: "dates_canceled_at",
        },
        {
          label: "Data de garantia",
          key: "dates_warranty_until",
        },
        {
          label: "Data de expiração",
          key: "dates_expires_at",
        },
        {
          label: "Expiração do pix",
          key: "payment_pix_expiration_date",
        },
        {
          label: "Produto de oferta",
          key: "product_name_offer",
        },
        {
          label: "Nome do envio",
          key: "shipping_name",
        },
        {
          label: "Valor do envio",
          key: "shipping_value",
        },
        {
          label: "Valor do remessa",
          key: "shipment_value",
        },
        {
          label: "Hora de entrega",
          key: "shipment_delivery_time",
        },
        {
          label: "Assinatura subscrição código",
          key: "subscription_subscription_code",
        },
        {
          label: "Cupom pagamento",
          key: "payment_coupon",
        },
        {
          label: "Nome do adquirente do pagamento",
          key: "payment_acquirer_name",
        },
        {
          label: "Tid do adquirente de pagamento",
          key: "payment_acquirer_tid",
        },
        {
          label: "Qrcode pix",
          key: "payment_pix_qrcode_signature",
        },
      ];

      const csvReport = {
        filename: `excel-pedidos-gold-spell.xls`,
        headers,
        // data: details?.length ? details : [],
      };

      setResultFile(csvReport);
    } catch (error) {
    } finally {
      setIsPreparingDownload(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (resultFile.headers.length) {
      const button = document.getElementById("downloadOrders");
      button.click();

      setResultFile({
        filename: "report.csv",
        headers: "",
        data: "",
      });
    }
  }, [resultFile]);

  return (
    <GlobalContext.Provider
      value={{
        searchingUsuario,
        setSearchingUsuario,

        searchingOrdersKiosks,
        setSearchingOrdersKiosks,

        user,
        setUser,

        logout,
        scrollToTop,
        dowloadExcel,

        resultFile,
        preparingDownload,

        orders,
        setOrders,

        ordersKiosks,
        setOrdersKiosks,

        searchingOrders,
        setSearchingOrders,

        isLoadingOrdersKiosks,
        setIsLoadingOrdersKiosks,

        isLoadingUsers,
        setIsLoadingUsers,

        isLoadingOrders,
        setIsLoadingOrders,

        currentPage,
        setCurrentPage,

        users,
        setUsers,

        startDate,
        setStartDate,

        endDate,
        setEndDate,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalProvider;
