import * as React from "react";

const BackArrowIcon = (props) => (
  <svg
    width={22}
    height={23}
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.5} width={22} height={22} rx={11} fill="#CBC698" />
    <mask id="path-2-inside-1_3815_2105" fill="white">
      <path d="M13.1349 14.8016L9.77626 11.4356L13.1349 8.06964L12.1009 7.03564L7.70093 11.4356L12.1009 15.8356L13.1349 14.8016Z" />
    </mask>
    <path
      d="M13.1349 14.8016L14.5491 16.2159C15.3296 15.4354 15.3303 14.1703 14.5507 13.389L13.1349 14.8016ZM9.77626 11.4356L8.36051 10.023C7.58151 10.8037 7.58151 12.0676 8.36051 12.8483L9.77626 11.4356ZM13.1349 8.06964L14.5507 9.48231C15.3303 8.70102 15.3296 7.43588 14.5491 6.65543L13.1349 8.06964ZM12.1009 7.03564L13.5151 5.62143C13.1401 5.24636 12.6314 5.03564 12.1009 5.03564C11.5705 5.03564 11.0618 5.24636 10.6867 5.62143L12.1009 7.03564ZM7.70093 11.4356L6.28671 10.0214C5.50567 10.8025 5.50567 12.0688 6.28671 12.8499L7.70093 11.4356ZM12.1009 15.8356L10.6867 17.2499C11.0618 17.6249 11.5705 17.8356 12.1009 17.8356C12.6314 17.8356 13.1401 17.6249 13.5151 17.2499L12.1009 15.8356ZM14.5507 13.389L11.192 10.023L8.36051 12.8483L11.7192 16.2143L14.5507 13.389ZM11.192 12.8483L14.5507 9.48231L11.7192 6.65697L8.36051 10.023L11.192 12.8483ZM14.5491 6.65543L13.5151 5.62143L10.6867 8.44986L11.7207 9.48386L14.5491 6.65543ZM10.6867 5.62143L6.28671 10.0214L9.11514 12.8499L13.5151 8.44986L10.6867 5.62143ZM6.28671 12.8499L10.6867 17.2499L13.5151 14.4214L9.11514 10.0214L6.28671 12.8499ZM13.5151 17.2499L14.5491 16.2159L11.7207 13.3874L10.6867 14.4214L13.5151 17.2499Z"
      fill="white"
      mask="url(#path-2-inside-1_3815_2105)"
    />
  </svg>
);
export default BackArrowIcon;
