import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={13}
    height={13}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m7.484 6.368 5.003-5.009A.797.797 0 0 0 11.362.233L6.36 5.243 1.358.232A.795.795 0 1 0 .233 1.36l5.003 5.009-5.003 5.008a.797.797 0 0 0 1.125 1.126l5.002-5.01 5.002 5.01a.797.797 0 0 0 1.125-1.126z"
      fill="#000"
    />
  </svg>
);
export default SVGComponent;
