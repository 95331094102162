import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import secureLocalStorage from "react-secure-storage";

import { GlobalContext } from "../../../../context/GlobalContext";
import { getOrders } from "../../../../services/Orders";

import Button from "../../../../components/Button";
import InputDate from "../../../../components/InputDate";
import InputSearchOrders from "../../../../components/InputSearchOrders";

const PageController = () => {
  const {
    resultFile,
    dowloadExcel,
    preparingDownload,
    setStartDate,
    setEndDate,
    setSearchingOrders,
    searchingOrders,
    setCurrentPage,
    currentPage,
    startDate,
    endDate,
    setIsLoadingOrders,
    setOrders,
    isLoadingOrders,
  } = useContext(GlobalContext);
  const [reset, setReset] = useState(false);
  const [updateOrders, setUpdateOrders] = useState(false);

  const resetFilter = () => {
    setSearchingOrders("");
    setStartDate(null);
    setEndDate(null);
    setReset(true);
    setUpdateOrders((prevState) => !prevState);
  };

  const findOrders = () => {
    getOrders({
      currentPage,
      search: searchingOrders,
      startDate,
      endDate,
      setIsLoadingOrders,
      setOrders,
    });
  };

  useEffect(() => {
    findOrders();
  }, [currentPage, updateOrders]);

  const user = secureLocalStorage.getItem("@USER_IMAGE/NAME");

  return (
    <div className="flex items-center justify-between laptop:max-w-[800px] laptopXl:max-w-[900px] desktop:max-w-[950px] desktopXl:max-w-[1200px]">
      <div className="flex items-center gap-3">
        <InputSearchOrders
          width={"laptop:max-w-[200px] laptopXl:max-w-[500px]"}
          height={"h-11"}
          bottom={"bottom-3.5"}
          value={searchingOrders}
          onBlur={(value) => {
            setCurrentPage(1);
            setSearchingOrders(value);
          }}
          reset={reset}
          setReset={setReset}
        />

        <InputDate width={"w-[200px]"} height={"h-11"} bottom={"bottom-3.5"} />

        <Button
          name={"Pesquisar"}
          backgroundColor={"bg-primarycolor"}
          textColor={"text-white"}
          height={"h-11"}
          fontSize={"text-sm"}
          width={"min-w-28"}
          onClick={findOrders}
          disabled={isLoadingOrders}
        />

        <Button
          name={"Limpar filtro"}
          textColor={"text-primarycolor"}
          height={"h-11"}
          fontSize={"text-sm"}
          width={"min-w-28"}
          border={"border-primarycolor border-1"}
          onClick={resetFilter}
          disabled={isLoadingOrders}
        />
      </div>

      <CSVLink id="downloadOrders" {...resultFile} />

      {user.role === "Administrador geral" && (
        <Button
          name={preparingDownload ? "Preparando download..." : "Exportar dados"}
          backgroundColor={"bg-primarycolor"}
          disabled={preparingDownload}
          textColor={"text-white"}
          height={"h-11"}
          fontSize={"text-sm"}
          width={"min-w-[180px]"}
          onClick={() => {
            dowloadExcel("admin");
          }}
        />
      )}
    </div>
  );
};

export default PageController;
