import React, { useState, useEffect, useContext } from 'react';
import SearchIcon from '../../images/svg/SearchIcon/SearchIcon';
import { GlobalContext } from '../../context/GlobalContext';

const InputSearchOrders = ({ height, width, bottom, value, reset, setReset, onBlur }) => {
  const [localValue, setLocalValue] = useState(value);
  const { setStartDate, setEndDate } = useContext(GlobalContext);

  useEffect(() => {
    if (reset) {
      setLocalValue('');
      setReset(false);
    }
  }, [reset, setReset]);

  const handleBlur = (event) => {
    onBlur(event.target.value);
  }

  useEffect(() => {
    if (localValue === '') {
      setStartDate('')
      setEndDate('')
    }
  }, [localValue]);

  return (
    <div className="relative z-0">
      <input
        className={`text-sx font-regularp rounded-full ${height} ${width} pl-12 bg-white border-1 border-border`}
        type="text"
        placeholder="Pesquisar"
        value={localValue}
        onBlur={handleBlur}
        onChange={(event) => setLocalValue(event.target.value)}
      />

      <div className={`absolute left-3 ${bottom} 2xl:left-4`}>
        <SearchIcon />
      </div>
    </div>
  );
};

export default InputSearchOrders;
