import { Navigate, Outlet } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";

import api from "../../services/api"

const PrivateRoute = () => {
  const token = secureLocalStorage.getItem('@USER_TOKEN');
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    const getValidateToken = async () => {
      try {
        const res = await api.get('/user/token', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        setValidate(res.data);
      } catch (error) {
        console.error(error);
        setValidate(false);
      }
    };

    if (token) {
      getValidateToken();
    }
  }, [token]);

  if (token && !validate) {
    return null;
  }

  return validate ? <Outlet /> : <Navigate to={"/login"} />;
};

export default PrivateRoute;
